<template>
  <module
    ref="module"
    id="mats"
    titleIcon="fa fa-desktop"
    :title="$t('mats')"
    :use-default-list="false"
  >
    <template slot="toolbar-global">
      <div v-if="inprogress == false">
        <p-button type="info" @click="showCreateModal=true">
          <i class="fa fa-plus-square"></i>
        </p-button>
      </div>
      <div class="col-md-1"></div>
      <div class="row" v-if="inprogress == false && clonedMATs.length > 0">
        <fg-input
          class="input-sm"
          :placeholder="searchMATtxt"
          v-model="searchmat"
          addon-right-icon="nc-icon nc-zoom-split"
          v-on:keyup="searchdamats"
        >
        </fg-input>
      </div>
    </template>

    <div slot="global">
      <div v-if="!inprogress" class="alert alert-primary">
        <div class="text-right">
          <p-switch
            v-model="switches.AutoCreate"
            on-text="ON"
            off-text="OFF"
            @input="trigTog('mat_create')"
          ></p-switch>

          <span class="mx-2 text-dark">{{ $t("autocreatematbooltxt") }}</span>

          <p-switch
            v-model="switches.AutoDelete"
            on-text="ON"
            off-text="OFF"
            @input="trigTog('mat_delete')"
          ></p-switch>

          <span class="ml-2 text-dark">{{ $t("autodeletematbooltxt") }}</span>
        </div>
      </div>

      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda"></i>
      </div>

      <div v-else class="row">
        <!-- Modal -->
        <modal :show.sync="showCreateModal" :required="true" headerClasses="justify-content-center">
          <h5 slot="header" class="modal-title">{{ txt.modalName }}</h5>
          <div class="row" style="height: 420px; align-content: center">
            <fg-input class="col-md-12" v-model="snapshot" v-validate="modelValidations.snapshot" name="snapshot" :label="$t('snapshottxt')" :error="getError('snapshot')"></fg-input>
            <fg-input class="col-md-12" v-model="plan" v-validate="modelValidations.plan" name="plan" :label="$t('plantxt')" :error="getError('plan')"></fg-input>
            <fg-input class="col-md-12" v-model="initials" v-validate="modelValidations.initials" name="initial" :label="$t('initials')" :error="getError('initial')"></fg-input>
            <fg-input class="col-md-12" v-model="capacity" v-validate="modelValidations.capacity" type="number" min="0" name="capacity" :label="$t('capacitytxt')" :error="getError('capacity')"></fg-input>
            <form-group-autocomplete class="col-md-12"  :source="options" v-model="name" v-validate="modelValidations.matgroup" name="matgroup" @selected="selOptions" @clear="clear" :label="$t('matgrouptxt')" :error="getError('matgroup')"></form-group-autocomplete>
          </div>
          <template slot="footer">
            <div class="left-side">
              <p-button link v-on:click="createItem"> {{ txt.create }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" link v-on:click="showCreateModal=false"> {{ txt.cancelbtn }}</p-button>
            </div>
          </template>
        </modal>
      </div>

      <!-- No Accounts -->
      <div
        v-if="clonedMATs.length == 0 && inprogress == false"
        class="text-center"
      >
        <h2>{{ $t("nomats") }}</h2>
      </div>

      <div class="row" v-if="inprogress == false && mats.length > 0">
        <div class="col-lg-3 col-md-6 col-sm-6" v-for="mat in mats">
          <card type="pricing" class="card-category">
            <h6 slot="header" class="card-category">{{ mat.mat_server }}</h6>
            <div class="count strategy-count">
              {{ mat.strategies }}
            </div>
            &nbsp;
            <div class="count master-count">
              {{ mat.followers }}
            </div>
            &nbsp;
            <div class="count follower-count">
              {{ mat.platforms }}
            </div>
            <p-button
              type="primary"
              round
              slot="footer"
              v-on:click="openMAT(`${mat.mat_server}`)"
              >{{ $t("openmat") }}
            </p-button>
          </card>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
import Vue from "vue";
import { Card } from "src/components/UIComponents";
import { Table, TableColumn } from "element-ui";
import PSwitch from "src/components/UIComponents/PSwitch.vue";
import swal from "sweetalert2";
import {Modal} from 'src/components/UIComponents'
import Autocomplete from 'vuejs-auto-complete'
import FormGroupAutocomplete from '../../../../UIComponents/Inputs/formGroupAutocomplete.vue';
import FormGroupNumeric from '../../../../UIComponents/Inputs/formGroupNumeric.vue';
Vue.use(Table);
Vue.use(TableColumn);

const user_data = JSON.parse(localStorage.getItem("user-info"));

export default {
  components: {
    Card,
    PSwitch,
    Modal,
    Autocomplete,
    FormGroupAutocomplete,
    FormGroupNumeric,
  },
  data() {
    return {
      inprogress: true,
      mats: [],
      clonedMATs: [],
      searchmat: null,
      searchMATtxt: this.$t("searchmats"),
      inprogresstxt:
        this.$t("loadingtxt") +
        " " +
        this.$t("mats") +
        ". " +
        this.$t("pleasewait"),
      switches: {
        AutoCreate: true,
        AutoDelete: true,
      },
      showCreateModal: false,
      txt: {
        modalName: this.$t('matcreationsettings'),
        create: this.$t('create'),
        cancelbtn: this.$t('cancel'),
      },
      snapshot: '',
      plan: '',
      initials: '',
      capacity: '',
      matgroup: '',
      options: [],
      name: '',
      modelValidations: {
        snapshot: {required: true, max: 50},
        plan: {required: true, max: 10},
        initials: {required: true, max: 5},
        capacity: {required: true},
        matgroup: {required: true, max: 30},
      },
    };
  },
  methods: {
    clear () {
      this.name = ''
    },
    selOptions (opt) {
      this.name = opt.selectedObject.name;
    },
    getError (fieldName) {
      return this.errors.first(fieldName);
    },
    loadMATsAuto(resp) {
      this.switches.AutoCreate = !!resp.data["mat_create"];
      this.switches.AutoDelete = !!resp.data["mat_delete"];
    },
    loadMATs(resp) {
      //console.log(resp);
      for (var i = 0; i < resp.data.length; i++) {
        const s = resp.data[i];
        this.mats.push(s);
        this.clonedMATs.push(s);
      }
      this.inprogress = false;
    },
    loadGroups(resp) {
      for (var y = 0; y < resp.data.length; y++) {
        const z = resp.data[y];
        this.options.push({
          'id': z['id'],
          'name': z['name']
        });
      }
    },
    openMAT(server) {
      location.href = "/admin_strategies/mat/" + server;
    },
    failOp(error) {
      console.log(error);
    },
    searchdamats() {
      if (this.inprogress == false) {
        this.mats = [];

        let allmats = this.clonedMATs.slice(0);
        let searchu = null;
        if (this.searchmat !== null && this.searchmat.length > 0) {
          searchu = new RegExp(this.searchmat, "gi");
        }

        for (var i = 0; i < allmats.length; i++) {
          let addto = true;

          let fullname =
            allmats[i]["mat_server"] + allmats[i]["accounts"].join(" ");

          if (searchu !== null && fullname.match(searchu) === null) {
            addto = false;
          }

          if (addto) {
            this.mats.push(allmats[i]);
          }
        }
      }
    },
    trigTog(flag_name) {
      if (flag_name == "mat_create") {
        var flag_val = this.switches.AutoCreate;
      } else {
        var flag_val = this.switches.AutoDelete;
      }
      this.$setMATsAuto({ flag_name: flag_name, flag_value: +flag_val }).then(
        this.matScriptChanged,
        this.failOp
      ); // todo: success and error methods
    },
    matScriptChanged() {
      console.log("successfully changed mat script");
    },
    operationConcluded() {
      this.snapshot = ''
      this.plan = ''
      this.initials = ''
      this.capacity = ''
      this.matgroup = ''
      this.inProgress = false
    },
    createItem(){
      let param = {
        snapshot: this.snapshot,
        plan: this.plan,
        initials: this.initials,
        capacity: this.capacity,
        mat_group: this.name
      }
      this.UpdateMatSettings(param).then(this.createItemSuccess, this.failOp)
    },
    createItemSuccess(resp){
      if(!resp.success) {
        return this.failOp()
      }
      this.showCreateModal = false
      swal('Good job!', 'New item created', 'success').then( () => {
        this.operationConcluded();
      })
    },
  },
  mounted() {
    this.$getMATsAuto().then(this.loadMATsAuto, this.failOp);
    this.$getMATs().then(this.loadMATs, this.failOp);
    this.$getMatGroups().then(this.loadGroups, this.failOp);
  },
  created() {
    this.$setTranslatingInProgress();
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress");
  },
};
</script>
<style lang="scss">
.count {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-weight: bold;
  height: 50px;
  line-height: 45px;
  text-align: center;
  min-width: 50px;
  padding: 0 10px;
  font-size: 25px;
}
.strategy-count {
  color: #ef8157;
  border: 3px solid #ef8157;
}
.master-count {
  color: #51bcda;
  border: 3px solid #51bcda;
}
.follower-count {
  color: #fbc658;
  border: 3px solid #fbc658;
}
</style>
